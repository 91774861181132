import React, { useEffect, useState } from "react";
import { NTab, EGet } from "../../../models/contants";
import { effect, useStore } from "../../../utils/dva16";
import MarketModel from "./MarketModel";
import MarketModelTable from "./MarketModelTable";
import "../../../index.less";
import { Tabs, Empty, Button } from "antd";
const { TabPane } = Tabs;

const MarketTabs = () => {
	const { tabList } = useStore(NTab);
	const [activeTab, setActiveTab] = useState("1");

	useEffect(() => {
		effect(NTab, EGet, { page_name: "market" });
	}, []);

	const onChange = (key) => {
		setActiveTab(key);
	};

	const customizeRenderEmpty = () => (
		<div style={{ textAlign: "center" }}>
			<Empty
				image={require("@/assets/images/check_you.png")?.default}
				imageStyle={{
					height: 150,
				}}
				description={
					<span style={{ fontSize: "1.5rem" }}>
						This page is undergoing maintenance to bring you enhanced
						functionality and content. We apologize for any inconvenience!
					</span>
				}
			></Empty>
		</div>
	);

	return (
		<>
			<Tabs
				onChange={onChange}
				activeKey={activeTab}
				type="card"
				className="custom-tabs"
				
			>
				{tabList?.[0]?.bi_tab == 1 && (
					<TabPane tab="Dashboard" key="1">
						<MarketModel />
					</TabPane>
				)}
				{tabList?.[0]?.table_tab == 1 && (
					<TabPane tab="Table" key="2">
						<MarketModelTable />
					</TabPane>
				)}
			</Tabs>
			{tabList?.[0]?.bi_tab != 1 && tabList?.[0]?.table_tab != 1
				? customizeRenderEmpty()
				: ""}
		</>
	);
};

export default MarketTabs;
