import React, { useEffect, useState } from "react";
import { Tabs, Empty } from "antd";
import { NTab, EGet } from "../../../models/contants";
import { effect, useStore } from "../../../utils/dva16";
import ICafeGame from "./ICafeGame";
import ICafeTable from "./ICafeTable";

const { TabPane } = Tabs;

const ICafeTabs = () => {
	const { tabList } = useStore(NTab);
	const [activeTab, setActiveTab] = useState("2");

	useEffect(() => {
		effect(NTab, EGet, { page_name: "icafe" });
	}, []);

	const onChange = (key) => {
		setActiveTab(key);
	};

	const customizeRenderEmpty = () => (
		<div style={{ textAlign: "center" }}>
			<Empty
				image={require("@/assets/images/check_you.png")?.default}
				imageStyle={{
					height: 150,
				}}
				description={
					<span style={{ fontSize: "1.5rem" }}>
						This page is undergoing maintenance to bring you enhanced
						functionality and content. We apologize for any inconvenience!
					</span>
				}
			></Empty>
		</div>
	);

	return (
		<>
			<Tabs
				onChange={onChange}
				activeKey={activeTab}
				type="card"
				className="custom-tabs"
			>
				{tabList?.[0]?.bi_tab == 1 && (
					<TabPane tab="Dashboard" key="1">
						<ICafeGame />
					</TabPane>
				)}
				{tabList?.[0]?.table_tab == 1 && (
					<TabPane tab="Table" key="2">
						<ICafeTable />
					</TabPane>
				)}
			</Tabs>
			{tabList?.[0]?.bi_tab != 1 && tabList?.[0]?.table_tab != 1
				? customizeRenderEmpty()
				: ""}
		</>
	);
};

export default ICafeTabs;
