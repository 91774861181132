import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/en-au";
import "./index.less";
import {
	Button,
	Row,
	Col,
	Space,
	Table,
	Form,
	Spin,
	DatePicker,
	Typography,
} from "antd";
import formatDate from "@/utils/dateFormater";
import { effect, useStore, reducer } from "../../../utils/dva16";
import { Helmet } from "react-helmet";
import CustomModal from "../../../components/modal";
import { ArrowLeftOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
import CustomEmpty from "@/utils/CustomEmpty";
import {
	NICafeGame,
	EGet,
	EGetCollect,
	EGetDetail,
	RSetState,
	MName,
} from "../../../models/contants";
moment.locale("en", {
	months: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	],
});
export default (props) => {
	const { id } = props.match.params;
	const [form] = Form.useForm();
	const { history } = props;
	const { ICafeGameDetail, loading } = useStore(NICafeGame);
	const [searchValues, setSearchValues] = useState();
	const [sortState, setSortState] = useState(null);
	const [filterValues, setFilterValues] = useState([]);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};
	const { Text } = Typography;
	const renderTable = () => {
		const columns = [
			{
				title: "Date",
				dataIndex: "date",
				key: "date",
				sorter: true,
				render: (text, record, index) => {
					const date = new Date(record?.date);
					const formattedDate = formatDate(date, "mmm yyyy");
					return <div>{record?.date ? formattedDate : ""} </div>;
				},
			},
			{
				title: "Launch Count",
				dataIndex: "data",
				key: "data",
				render: (text, record, index) => (
					<>{text && text?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>
				),
				sorter: true,
			},
		];
		const pagination = {
			current:
				parseInt(ICafeGameDetail && ICafeGameDetail?.icafeData?.page) || 1,
			total: (ICafeGameDetail && ICafeGameDetail?.icafeData?.total) || 0,
			defaultCurrent: 1,
			defaultPageSize:
				parseInt(ICafeGameDetail && ICafeGameDetail?.icafeData?.perPage) || 10,
			pageSize: ICafeGameDetail && ICafeGameDetail?.icafeData?.perPage,
			pageSizeOptions: ["10", "20", "30", "50", "100"],
			showQuickJumper: true,
			showSizeChanger: true,
			position: "bottom",
			showTotal: (total) => (
				<div>
					This page {ICafeGameDetail && ICafeGameDetail?.icafeData?.page} ，A
					total of
					{ICafeGameDetail?.icafeData?.total}
				</div>
			),
		};
		return (
			<Table
				locale={{ emptyText: CustomEmpty }}
				scroll={{ x: "max-content" }}
				className="CompanyTableBox"
				pagination={false}
				columns={columns}
				dataSource={ICafeGameDetail?.icafeData?.data || []}
				onChange={(pagination, filters, sort) => {
					const pageSize = ICafeGameDetail?.icafeData?.perPage || 10; // Get the pageSize from your data or default
					const currentPage = ICafeGameDetail?.icafeData?.page || 1; // Get the current page from your data or default

					setSortState(sort);
					effect(NICafeGame, EGetDetail, {
						game: id,
						perPage: pageSize,
						page: currentPage,
						field: sort?.column && sort?.field,
						isDesc:
							(sort?.column && sort?.order && sortType[sort?.order]) || null,
						...searchValues,
					});
				}}
			/>
		);
	};
	const [formValues, setFormValues] = useState({});
	useEffect(() => {
		effect(NICafeGame, EGet, { page: 1, perPage: 10 });
		effect(NICafeGame, EGetCollect, { page: 1, perPage: 10 });
		effect(NICafeGame, EGetDetail, { game: id });
		return () => {
			reducer(NICafeGame, RSetState, { ICafeGameDetail: null });
		};
	}, []);
	const onFinish = (value) => {
		value.startDate = value?.startDate
			? moment(value?.startDate?._d)?.format("YYYY")
			: null;
		value.endDate = value?.endDate
			? moment(value?.endDate?._d)?.format("YYYY")
			: null;
		console.log(value);
		delete value?.date;
		setSearchValues(value);
		const valuesArray = Object.values(value).filter(
			(val) =>
				val !== undefined &&
				val !== null &&
				!(Array.isArray(val) && val.length === 0)
		);
		closeModal();
		setFilterValues(valuesArray);
		closeModal();
		effect(NICafeGame, EGetDetail, {
			...value,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
			game: id,
		});
	};

	const onReset = () => {
		form.resetFields();
		setSearchValues();
		setFormValues([]);
		closeModal();
		effect(NICafeGame, EGetDetail, { game: id });
		setFilterValues([]);
	};
	const goBack = () => {
		history.push(`/china_data/internet_cafe`);
	};

	useEffect(() => {
		effect(MName, EGet, {
			modelName: ICafeGameDetail?.gameInfo?.gameEn,
		});
	}, [ICafeGameDetail?.gameInfo?.gameEn]);
	let resetField = (field) => {
		if (field) {
			form.resetFields([field]);
		}
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	return (
		<div className="GridBox">
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<Spin spinning={loading} size="large">
				<div className="RowBox">
					<div style={{ marginBottom: "1rem" }}>
						<Button
							type="default"
							htmlType="button"
							icon={<ArrowLeftOutlined />}
							onClick={goBack}
						>
							Back
						</Button>
					</div>
					<div className="Icafe_Details_Top">
						<div
							className="Icafe_Details_Logo"
							style={{
								backgroundImage: `url(${
									ICafeGameDetail?.gameInfo?.logo ||
									"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMIAAADDCAYAAADQvc6UAAABRWlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSSwoyGFhYGDIzSspCnJ3UoiIjFJgf8LAwSDCIMogwMCcmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsis7PPOq3QdDFcvjV3jOD1boQVTPQrgSkktTgbSf4A4LbmgqISBgTEFyFYuLykAsTuAbJEioKOA7DkgdjqEvQHEToKwj4DVhAQ5A9k3gGyB5IxEoBmML4BsnSQk8XQkNtReEOBxcfXxUQg1Mjc0dyHgXNJBSWpFCYh2zi+oLMpMzyhRcASGUqqCZ16yno6CkYGRAQMDKMwhqj/fAIcloxgHQqxAjIHBEugw5sUIsSQpBobtQPdLciLEVJYzMPBHMDBsayhILEqEO4DxG0txmrERhM29nYGBddr//5/DGRjYNRkY/l7"
								})`,
							}}
						></div>
						<div>
							<div className="Icafe_Name">
								{ICafeGameDetail?.gameInfo?.gameEn || "-"}
							</div>
							<div className="Icafe_Name">
								{ICafeGameDetail?.gameInfo?.gameCn || "-"}
							</div>
							<div className="Icafe_details">
								<span>Developer:</span>{" "}
								{ICafeGameDetail?.gameInfo?.developerNames?.join(", ") || "-"}
							</div>
							<div className="Icafe_details">
								<span>Publisher:</span>{" "}
								{ICafeGameDetail?.gameInfo?.publisherNames?.join(", ") || "-"}
							</div>
							<div className="Icafe_details">
								<span>Genre:</span> {ICafeGameDetail?.gameInfo?.genre || "-"}
							</div>
						</div>
					</div>
				</div>
				<div
					className="BlogBox"
					style={{
						borderTop: "1px solid rgba(40, 57, 94, 0.2)",
						padding: "40px 40px 20px 0px",
						width: "100%",
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",

							height: "60px",
						}}
					>
						<CustomModal
							isModalOpen={isModalOpen}
							showModal={showModal}
							closeModal={closeModal}
							filterValues={filterValues}
							content={
								<>
									{" "}
									<Form
										name="advanced_search"
										className="ant-advanced-search-form"
										// labelCol={{ span: 4 }}
										form={form}
										onFinish={onFinish}
									>
										<Row>
											<Col span={24}>
												<Form.Item
													name="date"
													labelCol={{ span: 24 }}
													label={
														<div className="headBox">
															<Text className="label" type="secondary">
																DATE
															</Text>
															<Text
																className="reset"
																onClick={() => {
																	resetField("startDate");
																	resetField("endDate");
																}}
																type="secondary"
																style={{
																	cursor: "pointer",
																}}
															>
																Reset
															</Text>
														</div>
													}
												>
													<Row gutter={16}>
														<Col span={12}>
															<Form.Item name="startDate" noStyle>
																<DatePicker
																	// format="year"
																	getPopupContainer={(triggerNode) =>
																		triggerNode.parentNode
																	}
																	style={{ width: "100%" }}
																	placeholder="Start Year"
																	picker="year"
																/>
															</Form.Item>
														</Col>
														<Col span={12}>
															<Form.Item name="endDate" noStyle>
																<DatePicker
																	// format="year"
																	picker="year"
																	getPopupContainer={(triggerNode) =>
																		triggerNode.parentNode
																	}
																	style={{ width: "100%" }}
																	placeholder="End Year"
																/>
															</Form.Item>
														</Col>
													</Row>
												</Form.Item>
											</Col>
											<Col span={24} style={{ textAlign: "right" }}>
												<Space>
													<Button
														type="primary"
														htmlType="submit"
														className="applyBtn"
													>
														Apply Now
													</Button>
													<Button
														type="default"
														htmlType="button"
														onClick={onReset}
														className="resetBtn"
													>
														Reset All
													</Button>
												</Space>
											</Col>
										</Row>
									</Form>
								</>
							}
						/>
					</div>
				</div>
				<div
					className="CompanyBox"
					style={{
						width: "100%",
					}}
				>
					<Row className="RowBox mt30">
						<Col span={24}>{renderTable()}</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
