import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { debounce } from "lodash";
import CustomEmpty from "@/utils/CustomEmpty";
import "./index.less";
import {
	Button,
	Col,
	Row,
	Tag,
	Form,
	DatePicker,
	Select,
	Pagination,
	Input,
	Space,
	Empty,
	Spin,
} from "antd";
import E from "../../../models/E";
import sortData from "../../../utils/sorter";
import { effect, useStore } from "../../../utils/dva16";
import {
	EGet,
	NFreeReport,
	NCompany,
	EGetType,
	NGame,
	NGenre,
	NPlatform,
} from "../../../models/contants";
import { customizeRenderEmpty } from "../../../utils/utils";
const { RangePicker } = DatePicker;
const { Option } = Select;
export default (props) => {
	const { reportPage, loading } = useStore(NFreeReport);
	const { companyData } = useStore(NCompany);
	const { GameList } = useStore(NGame);
	const { PlatformData, TypeList } = useStore(NPlatform);
	const [searchValues, setSearchValues] = useState();
	const { history } = props;
	const {
		location: { state },
	} = history;
	const [form] = Form.useForm();
	const [searchValue, setSeachValue] = useState({});

	useEffect(() => {
		effect(NGenre, EGet, { page: 1, perPage: 10 });
		effect(NCompany, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 10 });
		effect(NGenre, EGet, { page: 1, perPage: 10 });
		effect(NGame, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGetType, {});
		if (state) {
			form.setFieldsValue({
				...state,
			});
			setSeachValue({ ...searchValue, ...state });
		}
		effect(NFreeReport, EGet, { page: 1, perPage: 10, ...state });
		return () => {};
	}, []);
	const onFinish = (value) => {
		value.startDate = value?.date
			? moment(value?.date[0])?.format("YYYY-MM-DD")
			: null;
		value.endDate = value?.date
			? moment(value?.date[1])?.format("YYYY-MM-DD")
			: null;
		delete value?.date;
		setSearchValues(value);

		effect(NFreeReport, EGet, { ...value, page: 1, perPage: 10 });
	};

	const onSearchCompany = (value) => {
		effect(NCompany, EGet, { ...{ company: value }, page: 1, perPage: 10 });
	};
	const onSearchGame = (value) => {
		effect(NGame, EGet, { ...{ keyword: value }, page: 1, perPage: 10 });
	};
	const onSearchGenre = (value) => {
		effect(NGenre, EGet, { ...{ genre: value }, page: 1, perPage: 10 });
	};
	const onSearchPlatform = (value) => {
		effect(NPlatform, EGet, { ...{ platform: value }, page: 1, perPage: 10 });
	};

	const onPageChange = (page) => {
		effect(NFreeReport, EGet, { page, perPage: 10, ...searchValue });
	};
	const toDetails = (value) => {
		history.push(`/Profile/FreeReports/freeReportsDetail/${value}`);
	};
	const onReset = () => {
		form.resetFields();
		effect(NFreeReport, EGet, { page: 1, perPage: 10 });
		effect(NCompany, EGet, { page: 1, perPage: 10 });
		effect(NGame, EGet, { page: 1, perPage: 10 });
		effect(NGenre, EGet, { page: 1, perPage: 10 });
		effect(NPlatform, EGet, { page: 1, perPage: 10 });
	};
	return (
		<div>
			<div
				className="FreeReportsBox mt50"
				style={{ backgroundColor: "#F7F9FB", padding: "40px 40px 20px 0px" }}
			>
				<Form
					name="advanced_search"
					className="ant-advanced-search-form"
					labelCol={{ span: 8 }}
					form={form}
					onFinish={onFinish}
				>
					<Row>
						<Col span={8}>
							<Form.Item
								name="region"
								label="Region"
								rules={[
									{
										required: false,
										message: "Input something!",
									},
								]}
							>
								<Select showArrow placeholder="Plese Select">
									{E?.mainEnum?.map((vo, index) => (
										<Option key={index} value={vo?.text}>
											{vo?.text}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="keyWord" label="Keywords">
								<Input placeholder="Input here" autoComplete="off" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								//
								name="date"
								label="Date"
								//
							>
								<RangePicker
									style={{ width: "100%" }}
									getPopupContainer={(triggerNode) => triggerNode?.parentNode}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="company" label="Company">
								<Select
									showArrow
									getPopupContainer={(triggerNode) => triggerNode?.parentNode}
									showSearch
									filterOption={false}
									placeholder="Select"
									onSearch={debounce((value) => {
										onSearchCompany(value);
									}, 300)}
								>
									{companyData?.data?.map((item, index) => {
										return (
											<Option key={index} value={item?.id}>
												{item?.nameEn}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="game" label="Game">
								<Select
									showArrow
									getPopupContainer={(triggerNode) => triggerNode?.parentNode}
									showSearch
									filterOption={false}
									placeholder="Select"
									onSearch={debounce((value) => {
										onSearchGame(value);
									}, 300)}
								>
									{GameList?.data?.map((item, index) => {
										return (
											<Option key={index} value={item?.id}>
												{item?.nameEn}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="platform"
								label="Platform"
								rules={[
									{
										required: false,
										message: "Input something!",
									},
								]}
							>
								<Select
									showArrow
									getPopupContainer={(triggerNode) => triggerNode?.parentNode}
									showSearch
									filterOption={false}
									placeholder="Select"
									onSearch={debounce((value) => {
										onSearchPlatform(value);
									}, 300)}
								>
									{sortData(PlatformData?.data, "platform")?.map(
										(item, index) => {
											return (
												<Option key={index} value={item?.platform}>
													{item?.platform}
												</Option>
											);
										}
									)}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								name="type"
								label="Category"
								rules={[
									{
										required: false,
										message: "Input something!",
									},
								]}
							>
								<Select
									getPopupContainer={(triggerNode) => triggerNode?.parentNode}
									showArrow
									filterOption={false}
									placeholder="Select"
								>
									{sortData(TypeList?.mianType, "type")?.map((item, index) => {
										return (
											<Option key={index} value={item?.type}>
												{item?.type}
											</Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}></Col>
						<Col span={8} style={{ textAlign: "right" }}>
							<Space>
								<Button type="primary" htmlType="submit">
									Search
								</Button>
								<Button
									type="default"
									htmlType="submit"
									onClick={onReset}
									className="secReset"
								>
									Reset
								</Button>
							</Space>
						</Col>
					</Row>
				</Form>
			</div>

			<Spin spinning={loading} size="large">
				<div className="FreeReportsBox">
					<Row className="RowBox mt30">
						<Col span={24}>
							<ul className="ProlistBox">
								{reportPage?.error == 1009 || reportPage?.total > 0 || (
									<CustomEmpty />
								)}

								{reportPage?.data && reportPage?.error != 1009
									? reportPage?.data?.map((vo, i) => (
											<li key={i}>
												<div
													className="insight_list_cont"
													style={{ width: "100%" }}
												>
													<h3>
														<div
															style={{ cursor: "pointer" }}
															onClick={() => {
																toDetails(vo?.id);
															}}
														>
															{vo?.title}
														</div>

														<div className="insight_list_date_style">
															{moment(vo?.date)?.format("YYYY-MM-DD")}
														</div>
													</h3>
													<div className="Tag_List_Box">
														{vo?.withCompanys?.map((vi, ii) => (
															<Tag
																key={ii}
																color="#9E219E"
																onClick={() => {
																	history.push(
																		`/games_organizations/organizations/details/${vi?.id}`
																	);
																}}
																style={{
																	cursor: "pointer",
																	borderRadius: "20px",
																}}
															>
																{vi?.name}
															</Tag>
														))}
														{vo?.withGames?.map((vi, iii) => (
															<Tag
																key={iii}
																color="#19AE54"
																onClick={() => {
																	history.push(
																		`/games_organizations/games/details/${vi?.id}`
																	);
																}}
																style={{
																	cursor: "pointer",
																	borderRadius: "20px",
																}}
															>
																{vi?.gameEn}
															</Tag>
														))}
													</div>
													<div
														className="insght_lst_info"
														onClick={() => {
															toDetails(vo?.id);
														}}
													>
														{vo?.info}
													</div>
												</div>
											</li>
									  ))
									: customizeRenderEmpty()}
							</ul>
						</Col>
						<Col span={24} style={{ textAlign: "right" }}>
							{reportPage?.error != 1009 && reportPage?.total
								? reportPage?.total && (
										<Pagination
											current={reportPage?.page || 1}
											total={reportPage?.total || 0}
											pageSize={reportPage?.perPage}
											onChange={onPageChange}
											showSizeChanger
											showQuickJumper
											showTotal={(total) => `Total ${total} items`}
										/>
								  )
								: ""}
						</Col>
					</Row>
				</div>
			</Spin>
		</div>
	);
};
