import imgSource from "./assets";

import NewHome from "./pages/Dashboard/home";
import Exclusives from "./pages/Menu/Exclusives/Exclusives";
import Search from "./pages/Dashboard/search";
import ChinaGamesStreamingTracker from "./pages/Menu/Games/chinaGamesStreamingTracker";
import InsightsFeedForEsports from "./pages/Menu/Esports/insightsFeedForEsports.jsx";
import InsightsFeedForChina from "./pages/Menu/Games/insightsFeedForChina.jsx";
import EsportsDetails from "./pages/Menu/Insights Feed/newDetails";
import RegulatoryOrganizations from "./pages/Menu/Games/regulatoryOrganizations";
import Teams from "./pages/Menu/Esports/teams";
import Tournament from "./pages/Menu/Esports/tournament";
import TournamentDetail from "./pages/Menu/Esports/TournamentDetail";
import CompanyRevenue from "./pages/Menu/Financial/companyRevenue";
import ICafeTabs from "./pages/Menu/Games/ICafeTabs.jsx";
import ICafeGameMoreData from "./pages/Menu/Games/iCafeGameMoreData";
import InsightsFeedForFinancial from "./pages/Menu/Financial/insightsFeedForFinancial";
import InsightsFinancialDetails from "./pages/Menu/Insights Feed/newDetails";
import ISBN from "./pages/Menu/Games/ISBN";
import MaIpoData from "./pages/Menu/Financial/MaIpoData";
import VANAForAllTopics from "./pages/Menu/Insights Feed/VANAForAllTopics";
import VANAForAllTopicsDetails from "./pages/Menu/Insights Feed/newDetails";
import Blog from "./pages/Menu/Profile/blog";
// import BlogDetails from "./pages/Menu/Profile/blogDetails";
import BlogDetails from "./pages/Menu/Insights Feed/blogDetails.jsx";
import Company from "./pages/Menu/Profile/company";
import CompanyDetail from "./pages/Menu/Profile/companyDetail";
import FreeReports from "./pages/Menu/Profile/freeReports";
import freeReportsDetail from "./pages/Menu/Profile/freeReportsDetail";
import Game from "./pages/Menu/Profile/game";
import GameDetailsPaidSample from "./pages/Menu/Profile/gameDetailsPaidSample";
import UserInfo from "./pages/Menu/User/userInfo";
import Report from "./pages/Menu/Financial/Report";
import MarketModel from "./pages/Menu/MarketModel/MarketModel.jsx";
import MarketTabs from "./pages/Menu/MarketModel/MarketTabs.jsx";

const routes = [
	{
		icon: imgSource.menuHome,
		path: "/home",
		name: "home",
		exact: true,
		hideInMenu: true,
		routes: [
			{
				path: "/home",
				name: "home",
				exact: true,
				component: NewHome,
			},
		],
	},
	{
		icon: imgSource.menu_exclusive,
		path: "/nikoiq_exclusives",
		// name: "Niko Knowledge Briefs",
		name: (
			<div
				style={{
					// paddingTop: "15px",
					// marginTop: "-7px",
					display: "flex",
					flexDirection: "column",
					// alignItems: "center",
					// height: "40px",
					// maxHeight: "40px",
				}}
			>
				<div
					style={
						{
							// height: "25px",
							// maxHeight: "20px",
						}
					}
				>
					Niko Knowledge Briefs
				</div>
				{/* <div
					style={{
						height: "25px",
						maxHeight: "20px",
					}}
				>
					Briefs
				</div> */}
			</div>
		),
		menuName: "Exclusives",
		exact: true,
		component: Exclusives,
	},
	{
		icon: imgSource.menu_news,
		path: "/news_articles",
		name: "News & Articles",
		childs: ["VANA", "BlogPosts"],
		menuName: "News & Articles",
		exact: true,
		routes: [
			{
				path: "/news_articles/VANA",
				name: "VANA Articles",

				exact: true,
				menuName: "InsightsFeed",
				component: VANAForAllTopics,
			},
			{
				path: "/news_articles/vana/details/:id",
				name: "VANAForAllTopicsDetails",
				exact: true,
				hideInMenu: true,
				component: VANAForAllTopicsDetails,
			},
			{
				path: "/news_articles/blog_posts",
				name: "Blog Posts",
				exact: true,
				menuName: "BlogPosts",
				component: Blog,
			},
			{
				path: "/news_articles/blog_posts/details/:id",
				name: "Blog Details",
				exact: true,
				component: BlogDetails,
				hideInMenu: true,
			},
		],
	},
	{
		icon: imgSource.menu_market,
		path: "/market_model",
		name: "Market Model",
		menuName: "MarketModel",
		exact: true,
		component: MarketTabs,
	},
	{
		icon: imgSource.menuHome,
		path: "/Search",
		name: "Search",
		exact: true,
		hideInMenu: true,
		routes: [
			{
				path: "/Search",
				name: "Search",
				exact: true,
				component: Search,
			},
		],
	},

	{
		icon: imgSource.menu_esport,
		path: "/esports",
		name: "Esports",
		menuName: "Esport",
		exact: true,
		component: Tournament,
	},
	// {
	// 	icon: imgSource.menu1,
	// 	path: "/china_data/internet_cafe",
	// 	name: "Internet Cafe",
	// 	menuName: "ChinaICafe",
	// 	exact: true,
	// 	component: ICafeTabs,
	// },
	{
		path: "/esports/details/:id",
		name: "Tournament details",
		menuName: "Esport",
		exact: true,
		hideInMenu: true,
		component: TournamentDetail,
	},

	// {
	// 	icon: imgSource.menu3,
	// 	path: "/Esports",
	// 	name: "Esports",
	// 	exact: true,
	// 	routes: [
	// 		{
	// 			path: "/Esports/InsightsFeedForEsports/EsportsDetails/:id",
	// 			name: "EsportsDetails",
	// 			exact: true,
	// 			hideInMenu: true,
	// 			component: EsportsDetails,
	// 		},
	// 		{
	// 			path: "/Esports/Tournament",
	// 			name: "Tournaments",
	// 			exact: true,
	// 			component: Tournament,
	// 		},
	// 		{
	// 			path: "/Esports/Tournament/TournamentDetail/:id",
	// 			name: "Tournament details",
	// 			exact: true,
	// 			hideInMenu: true,
	// 			component: TournamentDetail,
	// 		},
	// 		{
	// 			path: "/Esports/Teams",
	// 			name: "Teams",
	// 			exact: true,
	// 			component: Teams,
	// 		},
	// 	],
	// },
	// {
	// 	icon: imgSource.menu4,
	// 	path: "/Financial",
	// 	name: "Financial Data",
	// 	exact: true,
	// 	routes: [
	// 		// {
	// 		// 	path: "/Financial/InsightsFeedForFinancial/InsightsFinancialDetails/:id",
	// 		// 	name: "Insight Feed For Financial",
	// 		// 	exact: true,
	// 		// 	hideInMenu: true,
	// 		// 	component: InsightsFinancialDetails,
	// 		// },
	// 		// {
	// 		// 	path: "/Financial/CompanyRevenue",
	// 		// 	name: "Earnings",
	// 		// 	exact: true,
	// 		// 	component: CompanyRevenue,
	// 		// },
	// 		{
	// 			path: "/Financial/Transactions",
	// 			name: "Transactions",
	// 			exact: true,
	// 			component: MaIpoData,
	// 		},
	// 		// {
	// 		// 	path: "/Financial/Reports",
	// 		// 	name: "Reports",
	// 		// 	exact: true,
	// 		// 	component: Report,
	// 		// },
	// 	],
	// },
	{
		icon: imgSource.menu_icafe,
		path: "/china_data",
		name: "China Data",
		childs: ["ChinaICafe", "ISBN"],
		menuName: "ChinaData",
		exact: true,
		routes: [
			{
				path: "/china_data/internet_cafe",
				name: "Internet Cafe",
				menuName: "ChinaICafe",
				exact: true,
				component: ICafeTabs,
			},
			{
				path: "/china_data/isbn",
				name: "ISBN",
				menuName: "ISBN",
				exact: true,
				component: ISBN,
			},

			{
				path: "/china_data/internet_cafe/details/:id",
				name: "Internet Game More Data",
				exact: true,
				hideInMenu: true,
				component: ICafeGameMoreData,
			},
		],
	},

	{
		icon: imgSource.menu_games,
		path: "/games_organizations",
		childs: ["Games", "Organizations"],
		menuName: "Games & Organizations",
		name: (
			<div
				style={{
					// paddingTop: "15px",
					marginTop: "-7px",
					display: "flex",
					flexDirection: "column",
					// alignItems: "center",
					height: "40px",
					maxHeight: "40px",
				}}
			>
				<div
					style={{
						height: "25px",
						maxHeight: "20px",
					}}
				>
					Games &
				</div>
				<div
					style={{
						height: "25px",
						maxHeight: "20px",
					}}
				>
					Organizations
				</div>
			</div>
		),
		exact: true,
		routes: [
			{
				path: "/games_organizations/games",
				name: "Games",
				menuName: "Games",
				exact: true,
				component: Game,
			},
			{
				path: "/games_organizations/organizations",
				name: "Organizations",
				menuName: "Organizations",
				exact: true,
				hideInMenu: false,
				component: Company,
			},
			// {
			// 	path: "/Games/RegulatoryOrganizations",
			// 	name: "Regulatory/Government",
			// 	exact: true,
			// 	component: RegulatoryOrganizations,
			// },
			{
				path: "/games_organizations/organizations/details/:id",
				name: "Company details",
				exact: true,
				hideInMenu: true,
				component: CompanyDetail,
			},

			{
				path: "/games_organizations/games/details/:id",
				name: "Game details",
				exact: true,
				hideInMenu: true,
				component: GameDetailsPaidSample,
			},
		],
	},

	{
		icon: imgSource.menu5,
		path: "/User",
		name: "User",
		exact: true,
		hideInMenu: true,
		routes: [
			{
				path: "/user/user_profile",
				name: "UserInfo",
				exact: true,
				component: UserInfo,
			},
		],
	},
];

export default routes;
